import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const Aboutpage = () => (
  <Layout location="/about" title="About">
    <Seo title="About" />
    <h1 itemProp="headline">About Me</h1>
    <hr />
    <p>CDP(Customer Data Platform)を作る会社でデータエンジニアをやっています。</p>
    <h5>Socials</h5>
    <p className="github"><a href="https://github.com/sakama">github.com/sakama</a></p>
    <p className="twitter"><a href="https://twitter.com/oreradio">twitter: oreradio</a></p>
    <hr />
  </Layout>
)

export default Aboutpage
